import React, { useEffect, useState, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import CharlesClough from '../../assets/images/team/charles.jpg'
import BillWhelan from '../../assets/images/team/bill_whelan_thumbnail.jpeg'
import { RESOURCES_QUERY, FUND_QUERY, DOWNLOAD_FILE } from '../../utils/queries'
import downloadIcon from '../../assets/images/download_icon.svg'
import docLinkIcon from '../../assets/images/doclink_icon.svg'
import { Link } from "react-router-dom";
import { performanceFormat } from '../../utils/helper'

function GLVFund(props) {
    const { ref1, ref2, ref3, ref4, ref5, activeTab, documentData } = props
    const ANNUALIZED = 'annualized'
    const CUMULATIVE = 'cumulative'
    const CALENDAR_YEAR = 'calendar_year'
    const TOP_10_HOLDINGS = 'top_10_holdings'
    const ALL_HOLDINGS = 'all_holdings'
    const CURRENT_YEAR = 'current_year'
    const PREVIOUS_YEAR = 'previous_year'
    const [currentList, setCurrentList] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [activePerformance, setActivePerformance] = useState(ANNUALIZED);
    const [activeHoldings, setActiveHoldings] = useState(TOP_10_HOLDINGS)
    const [activeDistribution, setActiveDistribution] = useState(CURRENT_YEAR)
    const [
        fetchResources,
        { data: resourceData, loading: resourceLoading, error: resourceError },
    ] = useLazyQuery(RESOURCES_QUERY);

    const [
        fetchFund,
        { data: fundData, loading: fundLoading, error: fundError },
    ] = useLazyQuery(FUND_QUERY);

    const [
        downloadFund,
        { data: downloadData, loading: downloadLoading, error: downloadError },
    ] = useLazyQuery(DOWNLOAD_FILE);

    useEffect(() => {
        fetchFund({ variables: { ticker: 'GLV' } });
        fetchResources({ variables: { ticker: 'GLV', documentType: documentData, documentTypes: documentData } });
    }, [documentData])

    const getUniqueFunds = (data) => {
        let sortArray = [...new Set(data?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))]
        const uniqueIds = [];
        const uniqueArray = sortArray.filter(element => {
            const isDuplicate = uniqueIds.includes(element['friendlyName']);
            if (!isDuplicate) {
                uniqueIds.push(element['friendlyName']);

                return true;
            }
            return false;
        });
        return uniqueArray
    }

    useEffect(() => {
        let completeFund = resourceData?.resources?.completeFundData
        let currentUpdates = []
        let reportUpdates = []
        completeFund?.length > 0 && completeFund?.map((data) => {
            if ((data?.type == 'irs_form' || data?.type == 'irs-form') && data?.funds?.length > 0) {
                reportUpdates.push(data?.funds.filter(i => i.friendlyName.slice(-4) === "2023"))
            }
            if ((data?.type == 'monthly_factsheet' || data?.type == 'monthly-factsheet' || data?.type == 'Monthlyfactsheet' || data?.type == 'education') && data?.funds?.length > 0) {
                currentUpdates?.push(data?.funds)
            }
            if ((data?.type == 'semi_annual_report' || data?.type == 'semi-annual-report') && data?.funds?.length > 0) {
                reportUpdates.push(data?.funds)
            }
            if ((data?.type == 'annual_report' || data?.type == 'annual-report') && data?.funds?.length > 0) {
                reportUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus_supplement' || data?.type == 'prospectus-supplement') && data?.funds?.length > 0) {
                reportUpdates.push(data?.funds)
            }
            if ((data?.type == 'prospectus') && data?.funds?.length > 0) {
                reportUpdates.push(data?.funds)
            }
        })
        // const uniqueReport = [...new Set(reportUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        // const uniqueCurrent = [...new Set(currentUpdates?.flat().sort((a, b) => b['createdAt']?.toString().localeCompare(a['createdAt']?.toString())).map(item => item))];
        setCurrentList(getUniqueFunds(currentUpdates))
        setReportList(getUniqueFunds(reportUpdates))
    }, [resourceData])
    useEffect(() => {
        if (activeTab) {
            if (activeTab == ANNUALIZED || activeTab == CUMULATIVE || activeTab == CALENDAR_YEAR) {
                setActivePerformance(activeTab)
            } else if (activeTab == TOP_10_HOLDINGS || activeTab == ALL_HOLDINGS) {
                setActiveHoldings(activeTab)
            } else if (activeTab == CURRENT_YEAR || activeTab == PREVIOUS_YEAR) {
                setActiveDistribution(activeTab)
            }
        }
    }, [activeTab])

    const exportFund = async (id, ticker) => {
        await downloadFund({ variables: { id: id, ticker: ticker } })
    }

    const getDistributionTable = (records, activeDistribution) => {
        const currentYear = new Date()?.getFullYear() || '2023'
        let tmpRecords = []
        if (activeDistribution == CURRENT_YEAR) {
            tmpRecords = records?.filter((x) => moment(x.exDate)?.year() == currentYear)
        } else {
            tmpRecords = records?.filter((x) => moment(x.exDate)?.year() !== currentYear)
        }
        return tmpRecords
    }

    const getFullHoldings = (data) => {
        let holdings = data.reduce((prevData, currentData) => {
            const { type } = currentData;
            if (prevData.hasOwnProperty(type)) {
                prevData[type].push(currentData);
            } else {
                prevData[type] = [currentData];
            }
            return prevData
        }, {})
        return holdings
    }

    return (
        <>

            <div class="container inner-content--callout video-element">

                <div id="fund_details" class="waypoint__subnav" ref={ref1}></div>

                <h3>Fund Details</h3>

                <h3 class="mod1">Objective</h3><br />
                <br />
                <p>Searching globally for the most opportunistic investments in the capital markets, the Fund's investment objective is to provide a high level of total return. The Clough Global Dividend and Income Fund seeks to pursue this objective by applying a fundamental research-driven investment process and will invest in equity and equity-related securities as well as fixed income securities, including both corporate and sovereign debt, in both U.S. and non-U.S. markets.</p>


                <h3 class="mod1">Fund Content</h3><br />
                <br />
                <p>The Fund intends to invest in a managed mix of equity and debt securities. The Fund is flexibly managed so that, depending on the Fund's investment adviser's outlook, it sometimes will be more heavily invested in equity securities or in debt or fixed income securities. Investments in non-U.S. markets will be made primarily through liquid securities, including depositary receipts (which evidence ownership in underlying foreign securities). Investments in corporate debt may include both investment grade and non-investment grade issues. Non-investment grade debt issues are commonly referred to as "high yield" or "junk bonds" and are considered speculative with respect to the issuer's capacity to pay interest and repay principal. The Fund will not invest more than 20% of its total assets in securities rated, at the time of acquisition, below investment grade. Investments in sovereign debt may also include bonds issued by countries considered emerging markets. The Fund will not invest more than 20% of its total assets, at the time of acquisition, in securities of governments and companies in emerging markets.</p>

            </div>


            <div class="container">

                <div class="col span_3_of_6">

                    <h3 class="mod1">Fund Management</h3><br />
                    <br />

                </div>
            </div>

            <div class="container">
                <div id="bioTarget">
                    <div class="row">
                        <p class="bio_p">
                            <img src={CharlesClough} alt="Charles I. Clough, Jr., CFA<sup>1</sup>" />
                            <b>Charles I. Clough, Jr., CFA<sup>1</sup></b>
                            <br />Chairman, CIO, Partner & Portfolio Manager: Clough Closed-End Funds<br />
                            <br />Chuck Clough is the Chairman and Chief Investment Officer of Clough Capital. He has been active in the securities and investment business for over 59 years. Prior to founding Clough Capital, Chuck served as the Chief Global Investment Strategist from 1987 through 1999 at Merrill Lynch & Co., Inc. During this time, he advised many of the world's top institutions and investors on portfolio strategy.<br />
                            <br />Prior to his tenure at Merrill Lynch, Chuck was Director of Investment Policy and Chief Strategist at Cowen & Co. Previously, he served as the Director of Research and a Portfolio Manager at the Boston Company, a Portfolio Manager at Colonial Management Associates and a Vice President and Senior Research Analyst for both Donaldson, Lufkin & Jenrette and Alliance Capital Management Company.<br />
                            <br />Chuck serves on the boards of a number of medical, educational and charitable institutions, including his alma mater, Boston College. Chuck graduated magna cum laude with a major in History and a minor in Economics from Boston College and earned an MBA at the University of Chicago. He has been a CFA® charterholder since 1979. Chuck is an ordained permanent deacon in the Roman Catholic Archdiocese of Boston and serves in that capacity at his local parish in Concord, MA.<br /><br />
                        </p>
                        <div class="disclosure">
                            <sup>1</sup>The CFA charter is a qualification for finance and investment professionals, particularly in the fields of investment management and financial analysis of stocks, bonds and their derivative assets. The program focuses on portfolio management and financial analysis, and provides a generalist knowledge of other areas of finance. It is a graduate level self study program offered by the CFA Institute (formerly AIMR) to investment and financial professionals. A candidate who successfully completes the program and meets other professional requirements is awarded a "CFA charter" and becomes a "CFA charterholder".<br /><br />
                        </div>
                        <br />
                        <p class="bio_p">
                            <img src={BillWhelan} alt="Bill Whelan" />
                            <b>Bill Whelan</b>
                            <br />Partner & Portfolio Manager: Clough Closed-End Funds<br />
                            <br />Bill Whelan joined Clough Capital in 2014 and has over 19 years of experience in the investment management industry. In 2023, Bill was named Co-Portfolio Manager of the Firm's three closed-end funds. Prior to joining the Clough Capital, Bill was an Investment Principal at Partners Capital, a private investment office focused on multi-asset class investing, from 2010 to 2014. Previously, Bill was an Equity Research Analyst at Millennium Management, a multi-strategy hedge fund, from 2008 to 2009 and at Fidelity Management and Research from 2005 to 2008. Bill earned a B.A. in Economics from Harvard College.<br /><br />
                        </p>
                    </div>
                </div>
            </div>


            <div class="container">
                <div className="spacer" />
            </div>


            <div class="container">


                <div id="dailyDataTableWrapper1" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                    <h3 class="mod1">Daily Data</h3>&nbsp;
                    <date>as of <span>{fundData?.fund?.dailyData?.asOfDate ? moment(fundData?.fund?.dailyData?.asOfDate).format("MM/DD/yyyy") : ''}</span></date>

                    <table class="table--mod-2">

                        <tbody><tr>
                            <td>Net Asset Value (NAV):<span>{fundData?.fund?.dailyData?.NAV ? `$ ${fundData?.fund?.dailyData?.NAV}` : '-'}</span>
                            </td>
                            <td>
                            </td>
                            <td>Premium/Discount:<span>{fundData?.fund?.dailyData?.premiumDiscount ? `${(fundData?.fund?.dailyData?.premiumDiscount * 100).toFixed(2)}%` : '-'}</span></td>
                        </tr>
                            <tr>
                                <td>Market Price:<span>{fundData?.fund?.dailyData?.marketPrice ? `$ ${fundData?.fund?.dailyData?.marketPrice}` : '-'}</span></td>
                                <td></td>
                                <td>Distribution Yield:<span>{fundData?.fund?.dailyData?.distributionRate ? `${(fundData?.fund?.dailyData?.distributionRate * 100).toFixed(2)}%` : '-'}</span></td>
                            </tr>

                        </tbody></table>

                </div>

                {/* <div id="dailyDataTableIndicator1" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div> */}

                <div className="spacer" />

            </div>

            <div class="container">
                <h3>Performance</h3>
                <div id="performance" class="waypoint__subnav" ref={ref2}></div>
                <div>
                    <div class="tabs" id="tabs__performance">
                        <ul class="tabs__list">
                            <li class="tabs__list-item">
                                <a class={`tabs__tab ${activePerformance == ANNUALIZED ? 'active' : ''}`} onClick={() => setActivePerformance(ANNUALIZED)}>ANNUALIZED</a>
                            </li>
                            <li class="tabs__list-item">
                                <a class={`tabs__tab ${activePerformance == CUMULATIVE ? 'active' : ''}`} onClick={() => setActivePerformance(CUMULATIVE)}>CUMULATIVE</a>
                            </li>
                            <li class="tabs__list-item">
                                <a class={`tabs__tab ${activePerformance == CALENDAR_YEAR ? 'active' : ''}`} onClick={() => setActivePerformance(CALENDAR_YEAR)}>CALENDAR YEAR</a>
                            </li>
                        </ul>
                        {activePerformance == ANNUALIZED &&
                            <div class="tabs__tab-content" style={{ display: "block" }}>

                                <div class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                                    <h3 class="mod1">Returns</h3>&nbsp;<date>as of {fundData?.fund?.quarterlyPerformance?.asOfDate ? moment(fundData?.fund?.quarterlyPerformance?.asOfDate).format("MM/DD/yyyy") : ''}</date>
                                    <table id="performanceTableID_quarterly" class="table--mod-3">
                                        <tbody><tr>
                                            <th>Fund</th>
                                            <th>1 Yr.</th>
                                            <th>3 Yr.</th>
                                            <th>5 Yr.</th>
                                            <th>10 Yr.</th>
                                            <th>S.I.</th>
                                        </tr>
                                            <tr>
                                                <td>Net Asset Value</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.oneYearNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.threeYearNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.fiveYearNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.tenYearNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.s1Nav)}</td>
                                            </tr>
                                            <tr>
                                                <td>Market Price</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.oneYearMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.threeYearMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.fiveYearMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.tenYearMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.quarterlyPerformance?.s1Market)}</td>
                                            </tr></tbody></table>

                                </div>

                                <div class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>

                            </div>
                        }
                        {activePerformance == CUMULATIVE &&
                            <div class="tabs__tab-content" style={{ display: "block" }}>

                                <div class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>


                                    <h3 class="mod1">Returns</h3>&nbsp;<date>as of {fundData?.fund?.monthlyPerformance?.asOfDate ? moment(fundData?.fund?.monthlyPerformance?.asOfDate).format("MM/DD/yyyy") : ''}</date>
                                    <table id="performanceTableID_monthly" class="table--mod-3">
                                        <tbody><tr>
                                            <th>Fund</th>
                                            <th>1 M.</th>
                                            <th>3 M.</th>
                                            <th>YTD</th>
                                            {/* <th>S.I.</th> */}
                                        </tr>
                                            <tr>
                                                <td>Net Asset Value</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.oneMonthNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.threeMonthNav)}</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.ytdNav)}</td>
                                                {/* <td>{fundData?.fund?.monthlyPerformance?.siNav ? ` ${(fundData?.fund?.monthlyPerformance?.siNav * 100).toFixed(2)}%` : '-'}</td> */}
                                            </tr>
                                            <tr>
                                                <td>Market Price</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.oneMonthMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.threeMonthMarket)}</td>
                                                <td>{performanceFormat(fundData?.fund?.monthlyPerformance?.ytdMarket)}</td>
                                                {/* <td>{fundData?.fund?.monthlyPerformance?.siMarket ? ` ${(fundData?.fund?.monthlyPerformance?.siMarket * 100).toFixed(2)}%` : '-'}</td> */}
                                            </tr></tbody></table>

                                </div>

                                <div class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>


                            </div>
                        }
                        {activePerformance == CALENDAR_YEAR &&

                            <div class="tabs__tab-content" style={{ display: "block" }}>

                                <h3 class="mod1">
                                    {/* <!--Yearly --> */}
                                    CALENDAR YEAR RETURNS <span class="asofdate"> as of 12/31/2024</span></h3>

                                <div id="tableWrapperID_yp" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>
                                    {/* <!-- scrollable --> */}
                                    {/* <!--<div id="sticky_header_WAY_yp" class="waypoint"></div>--> */}
                                    <table class="table--mod-3" id="yearlyPerformanceTable">

                                        <tr><th>Fund</th>
                                            {/* <th>2013</th> */}
                                            {/* <th>2014</th> */}
                                            <th>2015</th>
                                            <th>2016</th>
                                            <th>2017</th>
                                            <th>2018</th>
                                            <th>2019</th>
                                            <th>2020</th>
                                            <th>2021</th>
                                            <th>2022</th>
                                            <th>2023</th>
                                            <th>2024</th>
                                        </tr>
                                        <tr>
                                            <td>Net Asset Value</td>
                                            {/* <td>22.15%</td> */}
                                            {/* <td>5.33%</td> */}
                                            <td>-2.81%</td>
                                            <td>-0.27%</td>
                                            <td>19.90%</td>
                                            <td>-9.58%</td>
                                            <td>17.46%</td>
                                            <td>10.11%</td>
                                            <td>6.08%</td>
                                            <td>-24.71%</td>
                                            <td>0.41%</td>
                                            <td>9.28%</td>
                                        </tr>
                                        <tr>
                                            <td>Market Price</td>
                                            {/* <td>21.46%</td> */}
                                            {/* <td>6.40%</td> */}
                                            <td>0.81%</td>
                                            <td>0.18%</td>
                                            <td>22.19%</td>
                                            <td>-16.17%</td>
                                            <td>23.67%</td>
                                            <td>7.66%</td>
                                            <td>18.47%</td>
                                            <td>-31.76%</td>
                                            <td>-8.46%</td>
                                            <td>17.83%</td>
                                        </tr>
                                    </table>

                                </div>

                                <div class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>

                            </div>
                        }

                    </div>


                </div>

                <br />











                <p class="disclosure"><b>The performance data quoted represents past performance. Past performance does not guarantee future results and current performance may be lower or higher than the performance quoted. Performance reflects the deduction of management fees and other applicable expenses and assumes that all rights in the fund’s rights offering were exercised. Dividends and distributions, if any, are assumed for the purpose of this calculation to be reinvested at a price obtained under the fund’s dividend reinvestment plan. Returns do not reflect brokerage commissions on the purchase or sale of the fund’s shares.</b></p>

                <p class="disclosure"><b>For the most current month-end performance data please call 1-855-425-6844.</b></p>

                <p class="disclosure"><b>Investment returns and value of the fund shares will fluctuate so that an investor’s shares, when sold, may be worth more or less than their original cost.</b></p>

                <p class="disclosure">Fund inception date is <span>07/28/2004</span></p>

                <p class="disclosure">Returns for periods greater than one year are annualized.</p>

                <p class="disclosure">Performance information shown does not reflect the deduction of taxes that shareholders would pay on fund distributions or the sale of fund shares. An investment in the fund involves risk, including loss of principal.</p>

                <div className="spacer" />

            </div>

            <div class="container ">


                <h3>Holdings</h3>
                <div id="holdings" class="waypoint__subnav" ref={ref3}></div>
                <br />

                <div class="tabs" id="tabs__holdings">

                    <ul class="tabs__list">
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeHoldings == TOP_10_HOLDINGS ? 'active' : ''}`} onClick={() => setActiveHoldings(TOP_10_HOLDINGS)}>TOP 10 EQUITY HOLDINGS</a>
                        </li>
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeHoldings == ALL_HOLDINGS ? 'active' : ''}`} onClick={() => setActiveHoldings(ALL_HOLDINGS)}>ALL HOLDINGS</a>
                        </li>
                    </ul>

                    {activeHoldings == TOP_10_HOLDINGS &&
                        <div class="tabs__tab-content" style={{ display: "block" }}>

                            <div id="holdingsTableWrapper_partial" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                                <date id="partialHoldingsAsOfDate"><br />As of {(fundData?.fund?.top10Holdings?.length > 0 && fundData?.fund?.top10Holdings[0]?.asOfDate) ? moment(fundData?.fund?.top10Holdings[0]?.asOfDate).format("MM/DD/yyyy") : ''}, subject to change</date>

                                <table class="table--mod-3 holdings" id="partial-holdings-table">
                                    <tbody><tr><th>Security</th>
                                        <th>Type</th>
                                        <th>Weight</th>
                                    </tr>
                                        {fundData?.fund?.top10Holdings?.length > 0 && fundData?.fund?.top10Holdings?.map((data, idx) => {
                                            return (
                                                <tr>
                                                    <td>{data?.description}</td>
                                                    <td>{data?.type}</td>
                                                    <td>{data?.totalNetAssetPercent ? (data?.totalNetAssetPercent * 100)?.toFixed(2) + "%" : '-'}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td colspan={2}><b>Total as a % of Net Assets</b></td>
                                            <td>{fundData?.fund?.top10Holdings ? fundData?.fund?.top10Holdings.reduce((a, c) => { return a + parseFloat((c.totalNetAssetPercent * 100)) }, 0).toFixed(2) + "%" : ""}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div id="tableIndicator_holdings_part" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>


                            {/* <script defer="" src="assets/javascript/settings__holdings_partial_closed.js"></script> */}
                        </div>
                    }
                    {activeHoldings == ALL_HOLDINGS &&
                        <div class="tabs__tab-content" style={{ display: "block" }}>



                            <div id="holdingsTableWrapper_full" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>

                                <date id="fullHoldingsAsOfDate"><br />As of {(fundData?.fund?.fullHoldings?.length > 0 && fundData?.fund?.fullHoldings[0]?.asOfDate) ? moment(fundData?.fund?.fullHoldings[0]?.asOfDate).format("MM/DD/yyyy") : ''}, subject to change</date>
                                {Object.entries(getFullHoldings(fundData?.fund?.fullHoldings))?.length > 0 &&
                                    Object.entries(getFullHoldings(fundData?.fund?.fullHoldings)).map((holding) => {
                                        return (
                                            <table class="table--mod-3 holdings categories" style={{}}>
                                                <tbody>
                                                    <tr>
                                                        <th class="header" colspan="3"><h3 class="mod1">{holding[0]}</h3></th>
                                                    </tr>
                                                    <tr>
                                                        <th>Security</th><th>Type</th><th>Weight</th>
                                                    </tr>
                                                    {
                                                        holding[1]?.map((data, idx) => {
                                                            return (
                                                                <tr>
                                                                    <td>{data?.description}</td>
                                                                    <td>{data?.type}</td>
                                                                    <td>{data?.totalNetAssetPercent ? (data?.totalNetAssetPercent * 100)?.toFixed(2) + "%" : '-'}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td colspan={2}><b>Total as a % of Net Assets</b></td>
                                                        <td>{holding[1] ? holding[1].reduce((a, c) => { return a + parseFloat((c.totalNetAssetPercent * 100)) }, 0).toFixed(2) + "%" : ""}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        )
                                    })}
                            </div>

                            <div id="tableIndicator_holdings_full" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>


                        </div>
                    }
                </div>


                <p class="disclosure">According to the fund's holding disclosure policy, as approved by the Board of Trustees, the release of the month-end holdings information will be delayed at least 30 days.</p>
                <p class="disclosure">Holdings subject to change without notice.</p>
                <div className="spacer" />

            </div>



            <div class="container ">
                <h3>Distributions</h3>
                <div id="distributions" class="waypoint__subnav" ref={ref4}></div>
                <div class="tabs" id="tabs__distributions">

                    <ul class="tabs__list">
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeDistribution == CURRENT_YEAR ? 'active' : ''}  tab_override`} onClick={() => setActiveDistribution(CURRENT_YEAR)}>Current Year</a>
                        </li>
                        <li class="tabs__list-item">
                            <a class={`tabs__tab ${activeDistribution == PREVIOUS_YEAR ? 'active' : ''}  tab_override`} onClick={() => setActiveDistribution(PREVIOUS_YEAR)}>Previous Years</a>
                        </li>

                    </ul>

                    <div class="distributions-wrapper">


                        <div id="distributionsTableWrapperINV" class="tableHorizontalScroll" style={{ overflowX: "hidden" }}>
                            {activeDistribution == CURRENT_YEAR &&
                                <table id="table__distributions" class="table--reduced-padding distributions-table" style={{ display: "table" }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: "none" }}>Ex-Date</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Record Date</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Payable Date</th>

                                            <th style={{ textAlign: "center", border: "none" }}>Amount</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Type</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Section 19a</th>
                                        </tr>
                                        {getDistributionTable(fundData?.fund?.distribution, activeDistribution)?.length > 0 &&
                                            getDistributionTable(fundData?.fund?.distribution, activeDistribution)?.map((data, idx) => {
                                                return (
                                                    <tr key={idx} class="odd-row" style={{ height: "52.4px", padding: "0px", margin: "0px", width: "1190px", opacity: "1" }}>
                                                        <td>{data.exDate ? moment(data.exDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.recordDate ? moment(data.recordDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.payableDate ? moment(data.payableDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.amount ? `$ ${data.amount?.toFixed(5)}` : '-'}</td>
                                                        <td>{data.distributionType || '-'}</td>
                                                        <td>
                                                            {data?.sec19ANotice?.id && <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.sec19ANotice?.id}&ticker=${data?.sec19ANotice?.ticker}`} target="_blank" style={{}}>
                                                                <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                            </a>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            }
                            {activeDistribution == PREVIOUS_YEAR &&
                                <table id="table__distributions" class="table--reduced-padding distributions-table" style={{ display: "table" }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: "none" }}>Ex-Date</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Record Date</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Payable Date</th>

                                            <th style={{ textAlign: "center", border: "none" }}>Amount</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Type</th>
                                            <th style={{ textAlign: "center", border: "none" }}>Section 19a</th>
                                        </tr>
                                        {getDistributionTable(fundData?.fund?.distribution, activeDistribution)?.length > 0 &&
                                            getDistributionTable(fundData?.fund?.distribution, activeDistribution)?.map((data, idx) => {
                                                return (
                                                    <tr key={idx} class="odd-row" style={{ height: "52.4px", padding: "0px", margin: "0px", width: "1190px", opacity: "1" }}>
                                                        <td>{data.exDate ? moment(data.exDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.recordDate ? moment(data.recordDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.payableDate ? moment(data.payableDate).format("MM/DD/yyyy") : '-'}</td>
                                                        <td>{data.amount ? `$ ${data.amount?.toFixed(5)}` : '-'}</td>
                                                        <td>{data.distributionType || '-'}</td>
                                                        <td>
                                                            {data?.sec19ANotice?.id && <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.sec19ANotice?.id}&ticker=${data?.sec19ANotice?.ticker}`} target="_blank" style={{}}>
                                                                <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                                            </a>}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            }
                        </div>

                        <div id="distributionsTableIndicatorINV" class="tableOverflowIndicatorMobile">← Swipe to see additional data →</div>


                    </div>
                </div>

                <div className="spacer" />

            </div>


            <div class="container">


                <h3>Resources</h3>
                <div id="resources" class="waypoint__subnav" ref={ref5}></div>
                <h3 class="mod2 underline">Current Updates</h3>

                <table class="table--mod-1 table_alt2">
                    <tbody><tr>
                        <td><b>Document</b></td>
                        <td><b>Date</b></td>
                        <td><b>Download</b></td>
                    </tr>
                        {currentList?.length > 0 && currentList.map((data, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{data?.friendlyName}</td>
                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                    <td>
                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                        </a>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody></table>

                <br />

                <h3 class="mod2 underline">REPORTS</h3>

                <table class="table--mod-1 table_alt2">
                    <tbody><tr>
                        <td><b>Document</b></td>
                        <td><b>Date</b></td>
                        <td><b>Download</b></td>
                    </tr>
                        {reportList?.length > 0 && reportList.map((data, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{data?.friendlyName}</td>
                                    <td>{data.createdAt ? moment(data.createdAt).format("MM/DD/yyyy") : '-'}</td>
                                    <td>
                                        <a href={`${process.env.REACT_APP_API_LINK}download_resource/?id=${data?.id}&ticker=${data?.ticker}`} target="_blank" style={{}}>
                                            <img src={downloadIcon} width="22" height="22" alt="Download Section 19a PDF" title="Download" />
                                        </a>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>


                <br />
                <br />

                <Link to="/resources/glv" class="button--primary">Additional Resources</Link>

            </div>

            <div className="spacer" />
            <div className="spacer" />

        </>
    );
}

export default GLVFund;
